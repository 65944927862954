
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { formatDate, getUuid } from '@panstar/common'
import { validateEmails } from '@/utils/check'
import { acadTypeMap, degreeTypeMap, checkTypeMap, getOptions } from '@/utils/filters'
import { getJobList } from '@/api/jobs'
import { getCertList, getWorkList } from '@/api/candidate'
import { getPackageList, PackageInfo } from '@/api/package'
import { BatchOrderInfo, OrderSurveyInfo, MBatchOrderInfo } from '@/api/order'
import InputSearch from '@/components/InputSearch.vue'
import XBox from '@/components/XBox.vue'
import PackageBox from './packageBox.vue'
import ItemSelect from './itemSelect/index.vue'
import Tags from './tags.vue'
import CompanyEdit from './companyEdit.vue'
import AcctSelect from '@/components/AcctSelect.vue'

import type { IOption, CompanyInfo, EduInfo } from '@/utils/interface'
import type { ElForm } from 'element-ui/types/form'
import { array2Map, getMouth } from '@/utils/common'

@Component({
  components: { InputSearch, XBox, PackageBox, ItemSelect, Tags, CompanyEdit, AcctSelect },
  name: 'CheckSetting',
})
export default class extends Vue {
  /** 是否批量 */
  @Prop({ default: false }) isBatch!: Boolean
  /** 是否调查工作经历 */
  @Prop({ default: false }) isExperience!: Boolean

  /** 数据 */
  form = {
    acctId: '',
    credentialList: [] as string[],
    professionList: [] as string[],
    schoolList: [] as string[],
    companyIds: [] as string[],
    xlList: [] as string[],
    xwList: [] as string[],
  } as MBatchOrderInfo

  /** 是否需要默认背调套餐 */
  hasPackage = false

  /** 职位数据 */
  jobList: IOption[] = []
  /** 学历类型 */
  acadList: IOption[] = getOptions(acadTypeMap)
  /** 学位类型 */
  degreeList: IOption[] = getOptions(degreeTypeMap)
  /** 背调开展类型 */
  checkTypeList: IOption[] = getOptions(checkTypeMap)
  /** 职业证书类型 */
  zhiyeList: IOption[] = []
  /** 专业证书类型 */
  zhuanyeList: IOption[] = []
  /** 套餐数据 */
  packageList: PackageInfo[] = []
  /** 选中的调查项数据 */
  itemList: OrderSurveyInfo[] = []
  workList: CompanyInfo[] = []

  // workList: CompanyInfo[] = [
  //   {
  //     id: 'WORK20230713235848213',
  //     date: ['2017-02-01', '2020-07-01'],
  //     companyName: '五百丁科技有限公司',
  //     workInfo: {
  //       candidateId: 'CAND20230713235848212',
  //       workId: 'WORK20230713235848213',
  //       employType: '00',
  //       odCompany: '',
  //       companyName: '五百丁科技有限公司',
  //       confirmedCompany: '',
  //       startDate: '2017-02-01 00:00:00.000',
  //       endDate: '2020-07-01 00:00:00.000',
  //       address: '',
  //       department: '',
  //       position: 'web前端工程师',
  //       duty: '',
  //       quitType: '00',
  //       quitReason: '',
  //       checkType: '00',
  //       checkStart: '',
  //       custId: 'CUST20230530110816127',
  //       orderId: '',
  //       batchId: 'BATC20230713235848211',
  //     },
  //   },
  // ]

  rules = {
    xlList: [{ type: 'array', required: true, message: '请至少选择一个学历类型', trigger: 'change' }],
    xwList: [{ type: 'array', required: true, message: '请至少选择一个学位类型', trigger: 'change' }],
    acctId: [{ required: true, message: '请输入背调负责人', trigger: 'change' }],
    ccEmails: [
      // { required: true, message: '请输入抄送邮箱', trigger: 'blur' },
      { validator: validateEmails, trigger: 'blur' },
    ],
  }

  /** 当前套餐 */
  get curPackage() {
    return this.packageList.find(i => i.checked) || ({} as PackageInfo)
  }

  /** 额外调查项数据 */
  get extraItemList() {
    const ls = this.curPackage.surveyList || []
    return this.itemList.filter(i => !ls.find(j => j.surveyId === i.surveyId))
  }

  /** 教育背景 */
  get eduList() {
    return this.itemList.filter(i => i.surveyDomain === '02')
  }

  /** 资质证书 */
  get certList() {
    return this.itemList.filter(i => i.surveyDomain === '03')
  }

  /** 获取学历数量 */
  get hasXl() {
    return this.eduList.some(i => /学历/.test(i.surveyName))
  }

  /** 获取学位数量 */
  get hasXw() {
    return this.eduList.some(i => /学位/.test(i.surveyName))
  }

  /** 获取在校经历核验数量 */
  get schoolCnt() {
    const item = this.eduList.find(i => /在校经历/.test(i.surveyName))
    return item ? item.num : 0
  }

  /** 获取职业资格数量 */
  get zhiyeCnt() {
    const item = this.certList.find(i => /职业资格/.test(i.surveyName))
    return item ? item.num : 0
  }

  /** 获取专业资格数量 */
  get zhuanyeCnt() {
    const item = this.certList.find(i => /专业资格/.test(i.surveyName))
    return item ? item.num : 0
  }

  @Watch('itemList')
  onItemChange() {
    this.$emit('itemUpdate', this.itemList, this.form)
  }

  /** 获取部门数据 */
  async getJobList() {
    this.jobList = (await getJobList({})).map(i => {
      return {
        text: i.positionName,
        value: i.positionId,
      }
    })
  }

  /** 获取套餐数据 */
  async getPackageList(hasNoWork: boolean) {
    let packagelist = (await getPackageList({ hasNoWork })).map(i => ({ ...i, checked: false }))
    packagelist.forEach((e) => {
      if (e.surveyPrice && e.num) {
        e.price = (parseInt(e.surveyPrice) / e.num).toString()
      }
    })
    this.packageList = packagelist
  }

  /** 获取证书类型 */
  async getCertList() {
    const { zhiyeList, zhuanyeList } = await getCertList()
    this.zhiyeList = zhiyeList.map(i => ({ text: i.name, value: i.code }))
    this.zhuanyeList = zhuanyeList.map(i => ({ text: i.name, value: i.code }))
  }

  /** 时间格式 */
  fmt = 'yyyy年MM月'
  getRangeText(date: string[]) {
    if (date && date.length > 0) {
      let [start, end] = date
      let rangeText = ''
      if (start && end) {
        const month = getMouth(end) - getMouth(start)
        rangeText = `，供职${(month / 12) >> 0}年${month % 12}个月`
      }
      start && (start = formatDate(start, this.fmt))
      end && (end = formatDate(end, this.fmt))
      return `${start}-${end}${rangeText}`
    } else {
      return ''
    }
  }

  /** 回显数据 */
  setData(data: BatchOrderInfo) {
    this.form = {
      ...data,
      credentialList: data.credentialList || [],
      professionList: data.professionList || [],
      schoolList: data.schoolList || [],
      xlList: [],
      xwList: [],
      companyIds: [],
    }
    // console.log('setData', data)

    // 批量没有工作经历调查
    if (this.isExperience && !this.isBatch) {
      const id = data.candidateList[0]
      id &&
        getWorkList(id).then(ls => {
          this.workList = ls.map(i => ({
            id: i.workId,
            companyName: i.companyName,
            date: [i.startDate, i.endDate],
            workInfo: i,
            isCustom: false,
            checkType: '00',
            checkStart: '',
          }))
        })
      this.getPackageList(false)
    } else {
      this.getPackageList(true)
    }
  }

  onClickBox(checked: boolean, index: number) {
    // console.log('onClickBox', checked, index)
    this.packageList.forEach((i, ind) => {
      if (ind === index) {
        i.checked = checked
        this.itemList = checked ? i.surveyList.map(i => ({ ...i, num: 1 })) : []
      } else {
        i.checked = false
      }
    })
  }

  /** 编辑额外调查项 */
  onExtra() {
    if (this.curPackage.packageId) {
      const ls = this.curPackage.surveyList
      if (this.isExperience) {
        ;(this.$refs.itemSelect as ItemSelect).showDc(ls, this.itemList)
      } else {
        ;(this.$refs.itemSelect as ItemSelect).show(ls, this.itemList)
      }
      
    } else {
      this.$message({ message: '请先选择套餐', type: 'warning' })
    }
  }

  /** 更新额外调查项 */
  onExtraUpdate(itemList: OrderSurveyInfo[]) {
    this.itemList = itemList
  }

  /** 添加公司名称 */
  onCompanyEdit(e: Event, item?: CompanyInfo) {
    e.stopPropagation()
    e.preventDefault()
    ;(this.$refs.companyEdit as CompanyEdit).show(item)
  }

  onCompanyUpdate(data: CompanyInfo) {
    const info = this.workList.find(i => i.workInfo === data.workInfo)
    // 新增公司
    if (!info) {
      data.workInfo.workId = getUuid()
      data.isCustom = true
      data.id = data.workInfo.workId
      this.workList.push(data)
    }
    console.log('onCompanyUpdate', this.workList, data)
  }

  /** 开展类型更新 */
  onCheckTypeChange(index: number, val: string) {
    if (index === 0 && val === '00') {
      this.workList.forEach(i => {
        i.workInfo.checkType = val
      })
    }
  }

  /** 校验数据 */
  validate() {
    return new Promise<BatchOrderInfo>((resolve, reject) => {
      ;(this.$refs.form as ElForm).validate((valid, invalidFields) => {
        console.log('valid', valid, this.form)
        if (valid) {
          let error = ''
          const packageId = this.curPackage.packageId
          if (!packageId) {
            error = '请选择套餐'
          } else {
            this.form.surveyList = this.itemList
            // this.form.surveyList = this.itemList.map(i => ({
            //   num: 0,
            //   packageId: i.packageId,
            //   price: i.price,
            //   surveyId: i.surveyId,
            //   surveyName: i.surveyName,
            // }));
          }
          const { xlList, xwList, ...form } = this.form
          const eduList = [] as EduInfo[]
          xlList.forEach(i => {
            eduList.push({
              eduArea: '00',
              eduLevel: i,
              eduType: '00',
            } as EduInfo)
          })
          xwList.forEach(i => {
            eduList.push({
              eduArea: '00',
              eduLevel: i,
              eduType: '01',
            } as EduInfo)
          })
          const map = array2Map<CompanyInfo>(this.workList)
          const companyList = form.companyIds.map(i => map.get(i) || ({} as CompanyInfo))
          companyList.forEach((e) => {
            if (e.isCustom) {
              e.workInfo.workId = ''
            }
          })
          const data: BatchOrderInfo = {
            ...form,
            packageId,
            eduList,
            price: 132,
            preFeedbackTime: 3,
            credentialList: form.credentialList.filter(i => i),
            workList: companyList.map(i => i.workInfo).filter(i => i),
          }
          if (error) {
            this.$message({ message: error, type: 'warning' })
            reject(error)
          } else {
            resolve(data)
          }
        } else {
          reject(invalidFields)
        }
      })
    })
  }

  created() {
    this.getJobList()
    this.getCertList()
  }
}
