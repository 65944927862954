
import { Component, Vue } from 'vue-property-decorator';

import type { ElForm } from 'element-ui/types/form';
import type { CompanyInfo, WorkInfo } from '@/utils/interface';

@Component({ name: 'CompanyEdit' })
export default class extends Vue {
  /** 弹窗是否可见 */
  dialogVisible = false;

  /** 数据 */
  form = {} as CompanyInfo;

  /** 标题 */
  get title() {
    return `${this.form.id ? '编辑' : '新增'}任职公司`;
  }

  /** 显示 */
  show(data = {} as CompanyInfo) {
    const workInfo = data.workInfo || {};
    this.form = {
      id: workInfo.workId,
      companyName: workInfo.companyName,
      date: [workInfo.startDate || '', workInfo.endDate || ''],
      workInfo: workInfo,
      // isCustom: !!workInfo.workId,
      isCustom: data.isCustom ? true : false,
    }; // 浅拷贝
    this.dialogVisible = true;
  }

  /** 确认 */
  onOk() {
    (this.$refs.form as ElForm).validate(valid => {
      // console.log('valid', valid, this.form);
      if (valid) {
        this.dialogVisible = false;
        const { companyName, date, workInfo } = this.form;
        workInfo.companyName = companyName;
        if (date && date.length > 1) {
          workInfo.startDate = date[0];
          workInfo.endDate = date[1];
        } else {
          workInfo.startDate = ''
          workInfo.endDate = ''
        }
        this.$emit('update', this.form);
      } else {
        console.log('error submit!!');
      }
    });
  }
}
