
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getJobList } from '@/api/jobs'
import InputSearch from '@/components/InputSearch.vue'
import CheckBox from '@/components/CheckBox.vue'
import Tags from './tags.vue'

import type { IOption } from '@/utils/interface'
import type { CandidateInfo } from '@/api/candidate'
import { getPackageList, PackageInfo } from '@/api/package'

@Component({
  components: { InputSearch, CheckBox, Tags },
  name: 'PackageBox',
})
export default class extends Vue {
  /** 套餐信息 */
  @Prop({ type: Object, default: {} }) info!: PackageInfo

  /** 是否需要默认背调套餐 */
  hasPackage = false

  /** 职位数据 */
  jobList: IOption[] = []

  /** 套餐数据 */
  packageList: PackageInfo[] = []
  /** 调查项数据 */
  get itemList() {
    return this.info.surveyList || []
  }

  /** 工作经历 */
  get workList() {
    return this.itemList.filter(i => i.surveyDomain === '00')
  }

  /** 风险查询 */
  get riskList() {
    return this.itemList.filter(i => i.surveyDomain === '01')
  }

  /** 教育背景 */
  get eduList() {
    return this.itemList.filter(i => i.surveyDomain === '02')
  }

  /** 资质证书 */
  get certList() {
    return this.itemList.filter(i => i.surveyDomain === '03')
  }

  /** 获取部门数据 */
  private async getJobList() {
    this.jobList = (await getJobList({})).map(i => {
      return {
        text: i.positionName,
        value: i.positionId,
      }
    })
  }

  /** 获取套餐数据 */
  private async getPackageList() {
    let packagelist = await getPackageList()
    packagelist.forEach((e) => {
      if (e.surveyPrice && e.num) {
        e.price = (parseInt(e.surveyPrice) / e.num).toString()
      }
    })
    this.packageList = packagelist
    this.packageList[0].checked = true
  }

  /** 显示 */
  show(data = {} as CandidateInfo) {}

  /** 确认 */
  onCheckedChange(val: Boolean) {
    this.$emit('checkedChange', val)
  }

  created() {
    this.getJobList()
    this.getPackageList()
  }
}
