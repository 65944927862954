import { ExtOptions } from '@panstar/fetch';
import { QueryPage, QueryPageResult } from './index';
import { alphaUrl, ajaxApi, ajaxForm } from '@/utils/request';
import { findOption } from '@/utils/common';
import { OptionModule } from '@/store/modules';
import type { EduInfo, WorkInfo } from '@/utils/interface';

/** 候选人信息 */
export type CandidateInfo = {
  /** 候选人id */
  candidateId: string;
  /** 候选人姓名 */
  candidateName: string;
  /** 曾用名 */
  oldName: string;
  /** 手机号 */
  phone: string
  /** 邮箱 */
  email: string
  /** 应聘职位ID */
  positionId: string
  /** 应聘职位名称 */
  positionName: string;
  /** 授权方式，00：电子授权，01：纸质授权 */
  authType: string;
  /** 批次号 */
  batchId: string;
  /** 调查区域，00：国内，01：港澳台，02：海外 */
  checkArea: string;
  /** 候选人确认后的姓名 */
  confirmedName: string;
  /** 客户id */
  custId: string;
  /** 身份证件编号 */
  idNo: string;
  /** 身份证件类型，00：身份证，01：护照 */
  idType: string;
  /** 用人部门id */
  officeId: string;
  /** 用人部门名称 */
  officeName: string;
  officeIds: string[]
  /** 订单id */
  orderId: string;
  /** 简历文件id */
  resumeId: string;
  /** 向候选人显示的客户名 */
  showCustName: string;
  /** 添加候选人的账户id */
  acctId: string;
  /** 添加候选人的账户名称 */
  acctName: string;
  /** 背调套餐名称 */
  packageName: string;
  /** 背调进度 */
  orderStatus: string;
  /** 背调进度文本 */
  orderStatusName: string;
  /** 背调发起时间 */
  checkStartTime: string;
  /** 背调结束时间 */
  checkEndTime: string;
};

type CandidateInfoPage = QueryPage & Partial<CandidateInfo>;

/** 候选人分页查询 */
export const getCandidatePage = (params: CandidateInfoPage) =>
  ajaxApi('get', alphaUrl + '/candi/query/page', params) as Promise<QueryPageResult<CandidateInfo>>;

/** 新增/更新候选人 */
export const saveCandidate = (params: CandidateInfo) => {
  params.officeId = params.officeIds.join();
  params.officeName = findOption(OptionModule.officeList, params.officeId).text;
  params.positionName = findOption(OptionModule.jobList, params.positionId).text;
  return ajaxApi('post', alphaUrl + '/candi/save', params) as Promise<CandidateInfo['candidateId']>;
};

/** 上传简历 */
export const uploadResumeUrl = alphaUrl + '/candi/resume/upload';

/** 上传简历 */
export const uploadResume = (params: FormData) => ajaxForm('post', uploadResumeUrl, params) as Promise<unknown>;

/** 批量上传地址 */
export const uploadBatchUrl = alphaUrl + '/candi/batch/upload';

/** 上传结果 */
export type TUploadResult = {
  /** 批次号 */
  batchId: string;
  /** 成功数量 */
  num: number;
};
/** 批量导入候选人，导入成功会用data字段返回导入的候选人数量给客户确认 */
export const uploadBatch = (params: FormData) => ajaxForm('post', uploadBatchUrl, params) as Promise<TUploadResult>;

/** 下载候选人批量上传模板 */
export const downloadTemplate = () =>
  ajaxApi('get', alphaUrl + '/candi/template/download', {}, {
    downloadFile: '批量上传模版.xlsx',
    responseType: 'blob',
  } as ExtOptions);

/** 查询工作经历列表 */
export const getWorkList = (candidateId: WorkInfo['candidateId']) =>
  ajaxApi('get', alphaUrl + '/candi/work/query/list', { candidateId }) as Promise<WorkInfo[]>;
/** 新增工作经历 */
export const addWork = (params: WorkInfo) => ajaxApi('post', alphaUrl + '/candi/work/add', params);

/** 删除工作经历 */
export const removeWork = (params: Pick<WorkInfo, 'workId'>) =>
  ajaxApi('post', alphaUrl + '/candi/work/delete', params);

/** 查询教育经历列表 */
export const getEDUList = (params: Pick<WorkInfo, 'candidateId'>) =>
  ajaxApi('get', alphaUrl + '/candi/edu/query/list', params) as Promise<EduInfo[]>;
/** 新增教育经历 */
export const addEDU = (params: WorkInfo) => ajaxApi('post', alphaUrl + '/candi/edu/add', params);

/** 工作经历信息 */
export type CertInfo = {
  /** 编号 */
  code: string;
  /** 名称 */
  name: string
};
/** 查询证书类型列表 */
export const getCertList = () =>
  ajaxApi('get', alphaUrl + '/candi/cert/type/list') as Promise<{
    zhiyeList: CertInfo[]
    zhuanyeList: CertInfo[]
  }>;
