import { render, staticRenderFns } from "./candidateSelect.vue?vue&type=template&id=56cd0aac&scoped=true"
import script from "./candidateSelect.vue?vue&type=script&lang=ts"
export * from "./candidateSelect.vue?vue&type=script&lang=ts"
import style0 from "./candidateSelect.vue?vue&type=style&index=0&id=56cd0aac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56cd0aac",
  null
  
)

export default component.exports